export const iconfontList = [
    {
        name: '默认',//名称
        icon: 'icon-sucaiku_moren2x',//iconfont class
    },
    {
        name: '回收',//名称
        icon: 'icon-ai49',//iconfont class
    },
    {
        name: '充值',//名称
        icon: 'icon-chongzhi1',//iconfont class
    },
    {
        name: '消息',//名称
        icon: 'icon-xiaoxi',//iconfont class
    },
    {
        name: '等级管理',//名称
        icon: 'icon-dengjiguanli',//iconfont class
    },
    {
        name: '注册用户管理',//名称
        icon: 'icon-zhuceyonghuguanli',//iconfont class
    },
    {
        name: '月账单',//名称
        icon: 'icon-yuezhangdan',//iconfont class
    },
    {
        name: '帮助',//名称
        icon: 'icon-bangzhu',//iconfont class
    },
    {
        name: '菜单管理',//名称
        icon: 'icon-caidanguanli',//iconfont class
    },
    {
        name: '号码池',//名称
        icon: 'icon-haomachi',//iconfont class
    },
    {
        name: '操作日志',//名称
        icon: 'icon-caozuorizhi-1',//iconfont class
    },
    {
        name: '角色管理',//名称
        icon: 'icon-jiaoseguanli',//iconfont class
    },
    {
        name: '黑名单库',//名称
        icon: 'icon-heimingdanku',//iconfont class
    },
    {
        name: '接口管理',//名称
        icon: 'icon-jiekouguanli',//iconfont class
    },
    {
        name: '话单查询',//名称
        icon: 'icon-huadanchaxun',//iconfont class
    },
    {
        name: '模块管理',//名称
        icon: 'icon-mokuaiguanli',//iconfont class
    },
    {
        name: '日账单',//名称
        icon: 'icon-rizhangdan',//iconfont class
    },
    {
        name: '权限管理',//名称
        icon: 'icon-quanxianguanli',//iconfont class
    },
    {
        name: '拦截记录',//名称
        icon: 'icon-lanjiejilu',//iconfont class
    },
    {
        name: '套餐',//名称
        icon: 'icon-taocan',//iconfont class
    },
    {
        name: '黑名单',//名称
        icon: 'icon-heimingdan',//iconfont class
    },
    {
        name: '收支明细',//名称
        icon: 'icon-shouzhimingxi',//iconfont class
    },
    {
        name: '套餐管理',//名称
        icon: 'icon-taocanguanli',//iconfont class
    },
    {
        name: '系统枚举',//名称
        icon: 'icon-xitongmeiju',//iconfont class
    },
    {
        name: '我的号码',//名称
        icon: 'icon-wodehaoma',//iconfont class
    },
    {
        name: '质检总览',//名称
        icon: 'icon-zhijianzonglan',//iconfont class
    },
    {
        name: '我的话单',//名称
        icon: 'icon-wodehuadan',//iconfont class
    },
    {
        name: '我的套餐',//名称
        icon: 'icon-wodetaocan',//iconfont class
    },
    {
        name: '质检类型',//名称
        icon: 'icon-zhijianleixing',//iconfont class
    },
    {
        name: '绑定关系',//名称
        icon: 'icon-bangdingguanxi',//iconfont class
    },
    {
        name: '质检记录',//名称
        icon: 'icon-zhijianjilu',//iconfont class
    },
    {
        name: '配置管理',//名称
        icon: 'icon-peizhiguanli',//iconfont class
    },
    {
        name: '部门管理',//名称
        icon: 'icon-bumenguanli',//iconfont class
    },
    {
        name: '套餐购买',//名称
        icon: 'icon-taocangoumai',//iconfont class
    },
    {
        name: '用户管理',//名称
        icon: 'icon-yonghuguanli',//iconfont class
    },
    {
        name: '消费',//名称
        icon: 'icon-primary',//iconfont class
    },
    {
        name: '闹钟',//名称
        icon: 'icon-a-zu4',//iconfont class
    },
    {
        name: '购买',//名称
        icon: 'icon-yijiangoumai',//iconfont class
    },
    {
        name: '模板下载',//名称
        icon: 'icon-xiazaimoban',//iconfont class
    },
    {
        name: '修改',//名称
        icon: 'icon-piliangbianji',//iconfont class
    },
    {
        name: '设置',//名称
        icon: 'icon-shezhi',//iconfont class
    },
    {
        name: '对话',//名称
        icon: 'icon-duihua',//iconfont class
    },
    {
        name: '成功',//名称
        icon: 'icon-chenggong1',//iconfont class
    },
    {
        name: '失败',//名称
        icon: 'icon-shibai2x',//iconfont class
    },
    {
        name: '未开启',//名称
        icon: 'icon-weikaiqi',//iconfont class
    },
    {
        name: '拦截',//名称
        icon: 'icon-cishu_data-sheet-05',//iconfont class
    },
    {
        name: '使用',//名称
        icon: 'icon-cishu_data-sheet-04',//iconfont class
    },
    {
        name: '总次数',//名称
        icon: 'icon-cishu_data-sheet-01',//iconfont class
    },
    {
        name: '查询',//名称
        icon: 'icon-cishu_data-sheet-03',//iconfont class
    },
    {
        name: '费用',//名称
        icon: 'icon-cishu_data-sheet-02',//iconfont class
    },
    {
        name: '支付宝',//名称
        icon: 'icon-zhifubao',//iconfont class
    },
    {
        name: '充值',//名称
        icon: 'icon-chongzhi',//iconfont class
    },
    {
        name: '单价',//名称
        icon: 'icon-web-icon-',//iconfont class
    },
    {
        name: '已读',//名称
        icon: 'icon-u2076',//iconfont class
    },
    {
        name: '未读',//名称
        icon: 'icon-weidudianjihou',//iconfont class
    },
    {
        name: '日期',//名称
        icon: 'icon-date-remove',//iconfont class
    },
    {
        name: '锁定',//名称
        icon: 'icon-lock_1',//iconfont class
    },
    {
        name: '统计',//名称
        icon: 'icon-chart-pie-alt',//iconfont class
    },
    {
        name: '刷新',//名称
        icon: 'icon-copy-4-20',//iconfont class
    },
    {
        name: '菜单',//名称
        icon: 'icon-copy-4-22',//iconfont class
    },
    {
        name: '设置',//名称
        icon: 'icon-copy-4-161',//iconfont class
    },
    {
        name: '消息2',//名称
        icon: 'icon-copy-4-27',//iconfont class
    },
    {
        name: '公告',//名称
        icon: 'icon-copy-4-18',//iconfont class
    },
    {
        name: '下一步',//名称
        icon: 'icon-copy-4-19',//iconfont class
    },
    {
        name: '分享',//名称
        icon: 'icon-copy-4-17',//iconfont class
    },
    {
        name: 'bottom',//名称
        icon: 'icon-copy-4-36',//iconfont class
    },
    {
        name: '添加客户',//名称
        icon: 'icon-copy-4-24',//iconfont class
    },
    {
        name: 'top',//名称
        icon: 'icon-copy-4-35',//iconfont class
    },
    {
        name: '上一步',//名称
        icon: 'icon-copy-4-23',//iconfont class
    },
    {
        name: 'right',//名称
        icon: 'icon-copy-4-38',//iconfont class
    },
    {
        name: '电话',//名称
        icon: 'icon-copy-4-29',//iconfont class
    },
    {
        name: 'left',//名称
        icon: 'icon-copy-4-37',//iconfont class
    },
    {
        name: '呼入',//名称
        icon: 'icon-copy-4-25',//iconfont class
    },
    {
        name: '我',//名称
        icon: 'icon-copy-4-32',//iconfont class
    },
    {
        name: '排序',//名称
        icon: 'icon-copy-4-28',//iconfont class
    },
    {
        name: '时间',//名称
        icon: 'icon-copy-4-21',//iconfont class
    },
    {
        name: '下载',//名称
        icon: 'icon-copy-4-39',//iconfont class
    },
    {
        name: '呼出',//名称
        icon: 'icon-copy-4-26',//iconfont class
    },
    {
        name: '放大',//名称
        icon: 'icon-copy-4-40',//iconfont class
    },
    {
        name: '关闭',//名称
        icon: 'icon-copy-4-43',//iconfont class
    },
    {
        name: 'api',//名称
        icon: 'icon-copy-4-44',//iconfont class
    },
    {
        name: '钱包',//名称
        icon: 'icon-copy-4-33',//iconfont class
    },
    {
        name: '统计',//名称
        icon: 'icon-copy-4-30',//iconfont class
    },
    {
        name: '排序',//名称
        icon: 'icon-copy-4-31',//iconfont class
    },
    {
        name: '缩小',//名称
        icon: 'icon-copy-4-41',//iconfont class
    },
    {
        name: '更多',//名称
        icon: 'icon-copy-4-34',//iconfont class
    },
    {
        name: '位置',//名称
        icon: 'icon-copy-4-42',//iconfont class
    },
    {
        name: '设置',//名称
        icon: 'icon-copy-4-16',//iconfont class
    },
    {
        name: '编辑',//名称
        icon: 'icon-copy-4-04',//iconfont class
    },
    {
        name: '删除',//名称
        icon: 'icon-copy-4-03',//iconfont class
    },
    {
        name: '客服',//名称
        icon: 'icon-copy-4-06',//iconfont class
    },
    {
        name: '筛选',//名称
        icon: 'icon-copy-4-05',//iconfont class
    },
    {
        name: '语音',//名称
        icon: 'icon-copy-4-09',//iconfont class
    },
    {
        name: '首页',//名称
        icon: 'icon-copy-4-07',//iconfont class
    },
    {
        name: '退出',//名称
        icon: 'icon-copy-4-13',//iconfont class
    },
    {
        name: '提示',//名称
        icon: 'icon-copy-4-08',//iconfont class
    },
    {
        name: '复制',//名称
        icon: 'icon-copy-4-01',//iconfont class
    },
    {
        name: '通知',//名称
        icon: 'icon-copy-4-10',//iconfont class
    },
    {
        name: '消息',//名称
        icon: 'icon-copy-4-11',//iconfont class
    },
    {
        name: '搜索',//名称
        icon: 'icon-copy-4-15',//iconfont class
    },
    {
        name: '电话',//名称
        icon: 'icon-copy-4-12',//iconfont class
    },
    {
        name: '关闭',//名称
        icon: 'icon-copy-4-02',//iconfont class
    },
    {
        name: '提升',//名称
        icon: 'icon-copy-4-14',//iconfont class
    },
]