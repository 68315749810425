// 权限管理
import { Request } from '@/http/request'

// 权限树获取
export function getRuleTree(parameter: any) {
    return Request.axiosInstance.post('/system/rule/tree', parameter)
}
// 添加权限
export function addRule(parameter: any) {
    return Request.axiosInstance.post('/system/rule/add', parameter)
}
// 删除权限
export function deleteRule(parameter: any) {
    return Request.axiosInstance.post('/system/rule/delete', parameter)
}
// 修改权限
export function editRule(parameter: any) {
    return Request.axiosInstance.post('/system/rule/edit', parameter)
}
// 查看权限
export function getRule(parameter: any) {
    return Request.axiosInstance.post('/system/rule/get', parameter)
}

export default { getRuleTree, addRule, deleteRule, editRule, getRule }